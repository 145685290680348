<template>
  <div>
    <el-select class="m-2" placeholder="Lọc danh mục" size="large" style="width: 15%" @change="getDataHistoryCustomer()" v-model="filter">
      <el-option
          v-for="item in filters"
          :key="item.value"
          :label="item.label"
          :value="item.value"
      />
    </el-select>
    <div class=" table-responsive w-100 mt-4">
      <table class="table table-bordered ">
        <thead class="table-secondary">
        <tr align-items class=" " style="font-size: 13px">
          <th>Danh mục</th>
          <th>Nội dung chi tiết</th>
          <th>Người làm</th>
          <th>Thời gian</th>
        </tr>
        </thead>
        <tbody v-if="data.length == 0">
        <tr class="text-center">
          <td colspan="4">
            Không có dữ liệu
          </td>
        </tr>
        </tbody>
        <tbody>
        <tr v-for="(item, index) in data" :key="index">
          <td>{{ item.danh_muc }}</td>
          <td>{{ item.content }}</td>
          <td>{{ item.nguoi_lam ? (Number(item.nguoi_lam.id) + 100000) + ' - ' + item.nguoi_lam.name : '' }}</td>
          <td>{{ item.ngay_tao }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div style="display: flex;justify-content: center" class="col-md-12 edu-paginate mx-auto text-center">
        <paginate
            v-model="page"
            :page-count="last_page"
            :page-range="3"
            :margin-pages="2"
            :click-handler="clickCallback"
            :prev-text="'Trước'"
            :next-text="'Sau'"
            :container-class="'pagination b-pagination'"
            :pageLinkClass="'page-link'"
            :page-class="'page-item'"
            :next-link-class="'next-link-item'"
            :prev-link-class="'prev-link-item'"
            :prev-class="'page-link'"
            :next-class="'page-link'"
        >
        </paginate>
      </div>
    </div>
  </div>
</template>

<script>
import {GET_DATA_HISTORY_CUSTOMER} from "@/core/services/store/customer/customer.module";

export default {
  name: "customer-history",
  data() {
    return {
      filters: [
        {
          label: 'Kinh doanh',
          value: 'kinh_doanh'
        },
        {
          label: 'Tài chính',
          value: 'tai_chinh'
        },
        {
          label: 'Học tập',
          value: 'hoc_tap'
        },
        {
          label: 'Chăm sóc khách hàng',
          value: 'cham_soc_khach_hang'
        },
      ],
      filter: '',
      page: 1,
      last_page: 1,
      loading: false,
      data: []
    }
  },
  mounted() {

    this.getDataHistoryCustomer()
  },
  methods: {
    getDataHistoryCustomer() {
      let idUser = this.$route.params.id
      this.$store.dispatch(GET_DATA_HISTORY_CUSTOMER, { id : idUser, filter: this.filter, page:this.page }).then((data) => {
        this.data = data.data;
        this.last_page = data.pagination.last_page;
      })
    },
    clickCallback(obj) {
      this.page = obj;
      this.getDataHistoryCustomer()
    },
  }
}
</script>

<style scoped>

</style>
